import React, { createContext, useReducer, useEffect } from 'react';
import { UserApi } from '../services/api/user';
import { useUser } from './UserContext';

interface TableauDashboardState {
  currentDashboard: string;
  userSelected: string | null;
  token: string | null;
  dashboardTabIndex: number;
}

type TableauDashboardAction =
  | { type: 'setCurrentDashboard'; payload: string }
  | { type: 'setUserSelected'; payload: string | null }
  | { type: 'setToken'; payload: string | null }
  | { type: 'setDashboardTabIndex'; payload: number }
  | { type: 'resetState' };

// Tableau Dashboard Context initial state
const initialState: TableauDashboardState = {
  currentDashboard: 'executiveSummary', // one of jonathan's 6 standard dashboards
  userSelected: null, // user selected in the dropdown
  token: null,
  dashboardTabIndex: 0,
};

export const TableauDashboardContext = createContext<{
  tableauDashboardState: TableauDashboardState;
  tableauDashboardDispatch: React.Dispatch<TableauDashboardAction>;
}>({
  tableauDashboardState: initialState,
  tableauDashboardDispatch: () => null,
});

export const TableauDashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user: currentUser } = useUser();

  const getTableauJWT = async () => {
    try {
      const res = await UserApi.getTableauJWT();
      tableauDashboardDispatch({
        type: 'setToken',
        payload: res.data,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    if (currentUser?.organisationId) {
      getTableauJWT();
    }
  }, [currentUser]);

  const [tableauDashboardState, tableauDashboardDispatch] = useReducer(
    (dashboardState: TableauDashboardState, action: TableauDashboardAction) => {
      switch (action.type) {
        case 'setCurrentDashboard':
          return { ...dashboardState, currentDashboard: action.payload };
        case 'setUserSelected':
          return {
            ...dashboardState,
            userSelected: action.payload,
          };
        case 'setToken':
          return {
            ...dashboardState,
            token: action.payload,
          };
        case 'setDashboardTabIndex':
          return {
            ...dashboardState,
            dashboardTabIndex: action.payload,
          };
        case 'resetState':
          return initialState;
        default:
          return dashboardState;
      }
    },
    initialState
  );

  return (
    <TableauDashboardContext.Provider
      value={{
        tableauDashboardState,
        tableauDashboardDispatch,
      }}>
      {children}
    </TableauDashboardContext.Provider>
  );
};
